@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: dark light;

  /* Used to normalise font sizes across different themes */
  font-size: clamp(1rem, 0.8rem + 0.7vw, 1.3rem);
}

html,
body {
  padding: 0;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, */
  /* Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
}

body {
  font-family: var(--font-text);
  /* font-size: var(--font-text-m); */
  color: rgb(var(--color-text-500));
  background-color: rgb(var(--color-background-500));
  background-image: linear-gradient(
    to right,
    rgb(var(--color-background-400)) 15%,
    rgb(var(--color-background-500)) 50%,
    rgb(var(--color-background-400)) 85%
  );
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }

  body {
    /* TODO Find a more efficient/performant way of doing this */
    transition: background-color 500ms linear;
  }
}

* {
  box-sizing: border-box;
}

::selection {
  background: rgb(var(--color-accent-500));
}

:target::before {
  content: '';
  display: block;
  block-size: 3rem;
}

:target a {
  top: 3rem !important;
}

/* Headings */
*:where(h1, h2, h3, h4, h5, h6) {
  font-family: var(--font-heading);
}

h1 {
  font-size: var(--font-heading-3xl);
  line-height: 1.1;
}

h2 {
  font-size: var(--font-heading-2xl);
  line-height: 1.3333;
}

h3 {
  font-size: var(--font-heading-xl);
  /* line-height: 1.3333; */
}

h4 {
  font-size: var(--font-heading-l);
  /* line-height: 1.3333; */
}

h1, h2, h3, h4, h5, h6 {
  text-wrap: balance;
}

/* svg */
svg {
  max-block-size: 1.2em;
  inline-size: 1.2em;
}

/* Links */
a {
  color: rgb(var(--color-primary-50));
  text-decoration: none;
}

a:visited {
  color: rgb(var(--color-primary-100));
}

a:is(:hover, :active, :focus-visible) {
  outline: none;
  color: rgb(var(--color-primary-900));
}

/* tailwind prose plugin override */
.prose {
  font-size: var(--font-text-m);
}

.prose .lead {
  /* TODO adjust font size */
  font-size: var(--font-text-m);
}

/* Forms */
input {
  accent-color: rgb(var(--color-accent-500));
}

input[type='checkbox']:checked {
  background-color: rgb(var(--color-accent-500));
}

/* Loading progress bar */
#nprogress {
  --color: rgb(var(--color-accent));
}

#nprogress .bar {
  height: 3px;
  background: var(--color);
}

#nprogress .spinner {
  top: 4.5rem;
  right: 2rem;
}

#nprogress .spinner-icon {
  border-top-color: var(--color);
  border-left-color: var(--color);
}

#nprogress .peg {
  box-shadow: 0 0 10px var(--color), 0 0 5px var(--color);
}
